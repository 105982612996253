import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';

import {apiBaseUrl} from '../config/apiConfig';
import {OktaStorageType} from '../constants/okta';
import {SessionHTTPMetaData, SessionExemptURIs, MakeRequestHeader} from './session/types';
import createSession from './session/createSession';
import {postSessionError} from './session/createSessionError';
import {ServerError} from './getErrorMessage';
import StorageKeys from '../constants/storageKeys';

export enum Methods {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
  PATCH = 'patch',
}

interface RequestParams {
  url: string;
}

interface UpdateParams extends RequestParams {
  data: object | null;
  id: string | number;
}

const getToken = (): string | undefined => {
  // read access token from local storage
  const localStorageToken = JSON.parse(
    localStorage.getItem(OktaStorageType.OKTA_TOKEN_STORAGE) || '""',
  );
  return localStorageToken?.accessToken?.value;
};

async function getSession(): Promise<string | null> {
  let sessionID = sessionStorage.getItem(StorageKeys.LS_SESSION_ID);

  if (!sessionID) {
    try {
      sessionID = await createSession();

      if (sessionID != null) {
        sessionStorage.setItem(StorageKeys.LS_SESSION_ID, sessionID);
      } else {
        throw Error('Unable to establish session ID');
      }
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  return sessionID;
}

class HttpClient {
  private static instance = axios.create({
    baseURL: hardcodeApiBase(apiBaseUrl),
  });

  async get<TypeData>(options: AxiosRequestConfig) {
    return HttpClient.makeRequest<TypeData>({method: Methods.GET, ...options});
  }

  async request<TypeData>(options: AxiosRequestConfig) {
    return HttpClient.makeRequest<TypeData>(options);
  }

  async post<TypeData>(options: AxiosRequestConfig) {
    return HttpClient.makeRequest<TypeData>({
      method: Methods.POST,
      ...options,
    });
  }

  async put<TypeData>(options: AxiosRequestConfig) {
    return HttpClient.makeRequest<TypeData>({
      method: Methods.PUT,
      ...options,
    });
  }

  async delete<TypeData>(options: AxiosRequestConfig) {
    return HttpClient.makeRequest<TypeData>({
      method: Methods.DELETE,
      ...options,
    });
  }

  async patch<TypeData>(options: UpdateParams) {
    return HttpClient.makeRequest<TypeData>({
      url: `${options.url}/${options.id}`,
      method: Methods.PATCH,
      data: options.data,
    });
  }

  private static async makeRequest<TypeData>(params: AxiosRequestConfig) {
    const token = getToken();

    const headers: MakeRequestHeader = {Authorization: `Bearer ${token}`};
    if (params?.url && !SessionExemptURIs.includes(params.url)) {
      const sessionID = await getSession();
      if (sessionID) {
        headers[SessionHTTPMetaData.SESSION_ID_STORAGE] = sessionID;
      }
    }
    try {
      const response: AxiosResponse<TypeData> = await this.instance.request({
        ...params,
        headers,
      });
      return response.data;
    } catch (error) {
      let sessionID = sessionStorage.getItem(StorageKeys.LS_SESSION_ID);
      const axiosError = error as AxiosError<ServerError>;

      if (token && sessionID && params.url !== SessionHTTPMetaData.SESSION_ERROR) {
        await postSessionError({error: axiosError, headers});
      }

      return await Promise.reject(error);
    }
  }
}

// TODO: remove after DOS-2378 will be done
function hardcodeApiBase(realValue: string) {
  return window.location.origin === 'https://default-ls-onerxpe-onerx-pe-dev.truveris.com'
    ? 'https://default-onerxpeapipy-dev.truveris.com'
    : realValue;
}

export default new HttpClient();
